import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { getImage, getSrc } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"

import LayoutNew from "../../components/layout-new";
import HeroSection from "../../components/common/hero-section"
import Seo from "../../components/seo"
import htmlSerializer from "../../utils/htmlSerializer"

const PageThankYou2 = () => {
  const data = useStaticQuery(graphql`
    query PageThankYou2NewQuery {
      prismicQualifyFormThankYouPage2 {
        data {
          page_title
          red_bubble_title
          red_bubble_text {
            richText
          }
          page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
        }
      }
    }
  `)

  const doc = data.prismicQualifyFormThankYouPage2
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)

  return (
    <LayoutNew>
      <Seo 
        title={doc.data.page_title} 
        description={doc.data.page_title}
        image={imgSrc}
        noIndex
      />

      <HeroSection imgSrc={imgHero} title={doc.data.page_title} />

      <div className="bg-site-black pt-12 pb-12">
        <div className="w-full flex flex-col items-center">
          <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 rounded-3xl shadow-lg">
            <h2 className="text-white font-bold text-center text-xl md:text-2xl">{doc.data.red_bubble_title}</h2>
            <div className="thank-you-content text-white font-medium text-center text-lg md:text-xl mt-6 mb-0">
              <RichText
                render={doc.data.red_bubble_text.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutNew>
  )
}

export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([
    <script
      key="clickguard-script"
      dangerouslySetInnerHTML={{
        __html: 'window.cg_conversion = true;',
      }}
    />,
  ]);
};

export default PageThankYou2
